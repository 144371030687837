import { pageViews } from "../../../reducers/mainui.reducer";
import { visViewModes } from "../../../reducers/visualizations.reducer";

export const colorNumberModes = {
  NONE: 0,
  NUMERIC: 1,
  ALPHA: 2
};
export const CurrencyFormat = {
  None: 0,
  US: 1,
  EU: 2
};
export const mode = {
  None: 0,
  Both: 1, //Both admin and the multiple account
  OnlyAdmin: 2
};
export const zoomElement = {
  Slider: 0,
  RightSidebarButtons: 1
};
export const thumbnailTemplates = {
  DesignOnly: 0,
  DesignVars: 1,
  VarsBottomBar: 2, //shows main design,  variations and then bottom bar with design name
  BottomBarVars: 3, // shows main design, bottom bar with deisgn name and then variations
  MainDesignWithSize: 4
};
export const colorNumberPositions = {
  BesideColorPie: 0,
  InSideColorPie: 1
};
export const flaginCurrentMode = (isAdmin, flag) => {
  flag = flag === true ? 1 : flag === false ? 0 : flag;
  let flagStatus = false;
  if (isAdmin && flag !== mode.None) {
    flagStatus = true;
  } else if (!isAdmin && flag === mode.Both) {
    flagStatus = true;
  }
  return flagStatus;
};
export const PDF_filenameRule = {
  Default: 0,
  StarkAllegro: 1
};
export const dateFormat = {
  MMDDYYYY: 0,
  DDMMYYYY: 1
};
export const defaultFlags = {
  zoomElement: zoomElement.Slider,
  thumbnailTemplate: thumbnailTemplates.DesignOnly,
  colorSeparatedTexture: true, //individual texture for individual colors
  showColorPercentage: true, //show percentage of color in color swatch
  hasOrderSheet: true, //if false order sheet will not be shown
  defaultQualityIndex: 0, //index for rug quality
  defaultStandardSize: 0,
  defaultUnit: null, //default unit for rug (ft or cm).
  hasRugshare: false,
  hasHoverZoom: false, //zoom design/illustration on hover
  hasArp: false, //augmented rug presentation
  hasFavorites: true, // to show favorites tab
  showCopyright: true, //show copyright text?
  hasVps: false,
  showAddToCart: false,
  showBuyNow: false,
  showEcatModeOnly: false,
  showHistoryBack: false,
  showSaveImage: true, // download image feature
  separateDownloadIconPath: "", // set the path of the icon you want to show in download button
  applyColorPaletteinSameFolder: false, //color and material changes in a design is applied to other same folder designs (example in dalyn rugs)
  applyColorPaletteinVariations: false, //color and material changes in a design is applied to other same designs variations (example in dalyn rugs)
  hasMasterCarving: mode.Both,
  showMasterCarvingAsCheckbox: false,
  designThumbsAsBg: mode.None,
  fullPageThumb: mode.None, //true for showing as default screen, "SET" for loading from button null/false for hiding
  defaultSelectedTab: 0,
  defaultSelectedTabInRoomView: 1,
  showFullPageThumbOnBack: false, // show full page thumb view when user presses back button
  homeTemplate: pageViews.CREATEYOURRUG, //pageViews.HOME,
  allowCreateYourRugTemplate: false,
  showAllDesignsButton: mode.None,
  showWarningInfoInFooter: false, //show warning text in footer when colors from different palette are selected (eg: flipo)
  infoInFooter: "", //in shine by sho, company information in right sidebar footer
  customBorderUrl: "",
  hasExternalCssUrl: false, //when true, it loads companyurl/explorug.css ; when some string then it loads css from that string url
  allowInnerBorder: false, // should be set for bordertool
  thumbFromCDN: true,
  showThumbTexture: false,
  hasCinematic: false,
  showCinematicRoomRenderings: false,
  applyNormalMapInJPEG: false,
  linksToOtherAccounts: [],
  hasDisabledPrintScreen: true,
  isFelt: false,
  postToParentWhenLoaded: false,
  postToParentOnEveryColorChange: false,
  redirectToPageViewAfterLogin: "", //if true, redirect to specified pageview after login. Eg:redirectToPageViewAfterLogin:"CREATEYOURRUG"
  pageViewsOptions: {
    colors: {
      showOrdersheetBtnBelowColorsArea: true
    },
    app: {
      template: pageViews.CREATEYOURRUG
    }
  },
  customPrice: null, //this shouldbe object with conditions,operands, done in englishvillagelane
  // {
  //   condition: "OR",
  //     operands: [
  //       {
  //         condition: "LT",
  //         operands: [{ variable: "area" }, { variable: 50 }]
  //       },
  //       {
  //         condition: "GT",
  //         operands: [{ variable: "width" }, { variable: 12 }]
  //       }
  //     ]
  // }
  createYourRug: {
    showOrdersheetBtnBelowColorsArea: true,
    defaultZoom: 3,
    defaultDesignView: 1,
    scrollToDesignViewAfterVariationOnClick: ".stage-wrapper.deepzoom",
    scrollToDesignViewAfterSimilarColorOnClick: ".stage-wrapper.deepzoom",
    scrollToDesignViewAfterSimilarShapesOnClick: ".stage-wrapper.deepzoom",
    scrollToDesignViewAfterCollectionOnClick: ".stage-wrapper.deepzoom", //include css classname or id to scroll to that.
    showInteractiveCatalog: {
      hasInteractiveCatalog: false,
      interactiveCatalogUrl: "https://catalogs.explorug.com/explorug/",
      interactiveCatalogThumbUrl: ""
    },
    showIFrame: {
      hasIFrame: false,
      iFrameUrl: "https://catalogs.explorug.com/explorug/"
    },
    showCustomizeRug: true,
    detailViewsOptions: [
      //for showing only specific view
      "DESIGN_VIEW_ZERO_ZOOM",
      "DESIGN_VIEW_DEEPZOOM",
      "DESIGN_VIEW_PERSPECTIVE"
    ],
    showLanguageOptionsInCYR: false, //set true for showing language options in CYR
    saveColorPropsinQr: false,
    enableCustomSize: {
      customizeSizeAndColor: false,
      availableDesignSizes: [], //["160 x 240","60 x 90","120 x 180","200 x 300"]
      minMaxCustomSizeCm: {
        width: [30, 200],
        height: [30, 300]
      },
      minMaxCustomSizeFt: {
        width: [1, 10],
        height: [1, 10]
      },
      customizeColorBox: false
    }
  },
  interface: {
    showSidebarsByDefault: {
      left: true,
      right: true
    },
    showOnlyOneSideBarInMobile: "", //pass left or right which need to be shown
    showStory: {
      design: true,
      folder: true // set to false by default
    },
    iconsReplace: null, // [{  orig:'expand', new:'options'}] // (done for wovenedge)
    showTextInRoomView: false
  },
  leftSidebarTabs: {
    //this flag used only in default template eg:saccocarpet
    collectionTab: false,
    artworkTab: false
  },
  customDialogBoxContent: "", //done in jules flipo (show tutorial dialog box at beginning)
  showCustomDialogAtStart: false,
  showWordCloud: false,
  designBrowser: {
    showLeftSidebarByDefault: false,
    showOpeningImage: false,
    showCurrentOpenFolder: false
  },
  rugshare: {
    socialMediaShareDescription: "",
    shareCustomizedDesign: true,
    showQuickLinkModes: mode.OnlyAdmin //show quicklink icon in admin, multiple or both. note:rugshare in backend should be true
  },
  colorCustomizationTools: {
    showColorCustomizationTools: false, //for multiple accounts
    showMapColors: false, //mapcolors is shown in admin by default. Showmapcolors == true for multiple accounts
    addShuffleColors: false,
    addColorPercentToggle: false,
    addAutocolor: false,
    addPaletteTools: false
  },
  colorSelectionBox: {
    showColorName: false,
    showSimilarColors: true,
    showColorFilter: true,
    showColorPaletteDropDown: true, //for dropdown in colorselection box
    showCarving: mode.None, // individual carving
    customColorOptions: [], //to choose from color wheel,
    collectionFilter: [], // show color collection and width in ordersheet according to folder. Has folder, collection, width list
    addPlaceholderinWidthOptions: false,
    defaultWidthOptionValue: 0, // 0 - first widthoption will be chosen; if -1, placeholder will be shown
    placeholderTextinWidthOptions: "",
    swatchSize: 2,
    colorPaletteLabels: {
      colorLabelInfo: "", // appears inside dialog box
      labelTemplate: "",
      labels: [],
      colorLabelFilter: []
    },
    customColorPreviews: { tabs: [], baseUrl: "" }, //allegro wilton
    dynamicCSBWidth: null, // eg: [0] set selected color list index for which csb needs to be of dynamic width
    //and then add css in class ".dynamicCSBWidth"+index (eg: .dynamicCSBWidth0)
    closeCSBAfterColorSelected: false,
    searchColorInAllLists: false,
    showColorPomsImages: false,
    defaultColorDatabaseIndex: null
  },
  designListTree: {
    expandSelectedFolderInit: true,
    hasFilterBySize: false /** **warn:Set this to true only in jpeg accounts */,
    searchInFolder: false,
    customThumbPath: null, //for displaying thumbnails from external source eg:studiop
    separateThumbnailColumn: false, // true in davis rugs
    consolidateSearch: false,
    keepFoldersExpanded: false, //expand all folders by default, dont collapse other foledrs on expanding one
    smartResizeOff: 0,
    sortDesignInDescending: false,
    shuffleDesigns: false,
    searchDesignVariations: false
  },
  fileManager: {
    forceUnit: null //set "in" for chacko; uploads designs in inch/ft without converting to cm
  },
  visualizations: {
    defaultView: "DESIGN_VIEW_DEEPZOOM", //DESIGN_VIEW_DEEPZOOM or ROOM_VIEW
    designViews: ["DESIGN_VIEW_DEEPZOOM"],
    myRoomEnabled: true,
    showIcebreakerinMyroom: true,
    myroomTutorialLink: "https://alternativetechnology.zendesk.com/hc/en-us/articles/900000724783",
    showBackToDesignViewInRoomView: false,
    showARcardInIcebreaker: false,
    wallToWallCenterRepeat: {
      x: false,
      y: false
    },
    textureWrapType: null,
    allowDesignFromUrlInMyroom: false,
    myroomIcebreakerBackground: "PERSPECTIVE",
    takeAPictureImgUrl: "",
    usePlainFbx: false,
    renderDesignViews: true,
    keepDesignTopViewInRoomlist: false,
    keepPerspectiveViewInRoomlist: false,
    renderDesignsInVisualizationTab: false,
    isBorderRugs: false,
    applyLinearFilter: true, // flag set false in Regence home to avoid application of linear filter
    maxCanvasSize: null, // set as array for capping design texture size [maxwidth, maxheight] e.g. [2000, 2000]
    showRuler: true, // set to false by default
    rulerDefaultUnit: null,
    maximizeGapInRulerForHighRes: 1, //done for high texture designs reduce gap in scaling and also change max,min,default zoom. done for highlandpashmina
    showScaleOption: false, //show carpetresize in roomview
    showScaleOptionDetails: false, //on scale updown also show carpet details
    changeCanvasSizeInCtf: false, //done for sizedemo; change ctf sizes width height
    renderDesignSpecific: 2, //1X,2X,4X => provide value as 1,2, or 4
    showGizmoInRoomView: true, //false if we no need to show gizmo, done in adnc
    setPileAngleInVisualizationTiles: null, //done in sirecom to change pileangle of carpet - 25(pass number)
    showAllRoomFiles: false, //show every rooms inside each and every folder(internal folder is only shown in admin)
    widthSaveImageWWPx: null, //set width for wall to wall while saving design-value in pixel
    heightSaveImageWWPx: null, //set width for wall to wall while saving design-value in pixel
    showCameraIconInStageMyRoom: false,
    multiplicationFactorForNormalMap: 1, //used to show increment in pileheight,done for tamariancustom
    renderingTextureResize: false, //done to resize the texture in different zoom views, done for tamariancustom
    donotMoveDesignOnMouseEventInRoomView: false //bydefault false, enable it to keep design fixed in room view
  },
  designView: {
    minZoom: 0.75,
    maxZoom: 3,
    defaultZoom: 2,
    minZoomJpeg: 0,
    maxZoomJpeg: 1,
    wallToWallLockAxis: null, //null, x, y
    hasDesignWaterMark: false,
    watermarkOptions: {
      position: [1, 1],
      opacity: 1,
      width: 75
    },
    dpi: 1,
    clampZoom: false, //set true in linkoutdoor
    zoomForMobile: null, //set to different value in mobile devices eg: 2x in desktop, 0.75 in mobile; allegro
    panTo: "CENTER"
  },
  perspectiveView: {
    configUrl: null,
    renderDefaultFloor: true
  },
  catalogViews: null,
  designDetailSection: {
    isCollapsible: false,
    showDesignPathInTitle: false,
    showSizeSelection: true,
    showDesignExhibitionPrice: false,
    exhibitorName: "",
    showDesignDimensions: true, //false to hide, true to show in default unit, 2 to show dimensions in both units(ft,cm) [set 2 in ktmrugs]
    showAddToFavoriteText: false,
    blockDesignName: false,
    showOneDimensionIfSquare: false,
    showAsSidebarActionBtns: false,
    designNameTildeWithNextLine: false,
    showDimensionInInches: false
  },
  designVariations: {
    appendDesignName: true, //appends design name to variation name if true, not used currently
    sayOriginal: true,
    containsOriginalDesign: false, //linkoutdoor
    putOriginalFirst: true, //set false in jpeg accounts until test case found
    preserveVariations: false, //set to true in studiop, where selected variation persists in all design
    expandVariations: false,
    expandColorVariations: false,
    expandShapeVariations: false,
    useOriginalName: false, //design Cubinia.ctf variation Cubinia 9x12.ctf shows Cubinia
    showTitlewithSymbolForDesignVariations: "" //show symbol in title name which have designvariations, done in fayette studio
  },
  colorAreaSwatch: {
    isCollapsible: false,
    hasMixply: mode.None, // allow mixply in multiple accounts
    showColorPercentage: true, //show percentage of color in color swatch
    colorNumberMode: colorNumberModes.NONE,
    colorNumberPosition: colorNumberPositions.BesideColorPie,
    showRedoButton: false,
    hasAllInOneMaterialSelection: false, //show radio btns for material selection  of all colors eg:vifloor
    allInOneMaterialSelection: {
      //Done in vifloor, obeeteeusa
      groupLabel: "Construction",
      values: ["Cut Pile", "Tip Shear"],
      labels: ["Cut Pile", "Tip Shear"],
      selectedValue: "Cut Pile"
    },
    shape: "CIRCLE", //"RECTANGLE_H", "SQUARE", "RECTANGLE_V",
    // mixPlyOptions: [
    //   { ply: 1, text: "Single Color" },
    //   { ply: 2, text: "2-Ply" },
    //   { ply: 3, text: "3-Ply" },
    //   { ply: 4, text: "4-Ply" }
    // ]
    mixPlyOptions: [
      { ply: 1, text: "Single Color" },
      { ply: 3, text: "Mix Ply" }
    ],
    sameColorLock: false,
    sameMaterialForAll: false,
    exclusiveMatArr: [], // [0, 1] if mat 0 and 1 are exclusive. [[0,1],2] if 0,1 can appear together but not with 2.
    exclusiveMatArrMode: mode.Both, //only in action when donotshowdropdown flag has values
    donotShowMatArr: [], //used in case of exclusive if we need to remove specific materials, done for adnc
    showMultipleMatDropdowns: false,
    multipleMatDropdowns: {
      first: [],
      second: []
    },
    setCustomPileHgtNameOnSelection: false,
    showTexture: false,
    colorNamePrefix: "",
    toolTipOnColorBoxHover: false,
    colorSelectionDialogBoxPosition: "left", //position of the color-selection-box that appears after clicking on any color
    showAutoColorDropdown: true,
    autoColorOptions: null, // format of the options should be like in autocolorlist.js file
    selectAutoColorFromAllColorTabs: false, //during autocolor, select from all possible colorbank instead of only one tab
    differentSymbolForAllColors: false,
    showColorPomsImages: false,
    allowPaletteFromImage: false, // set it to false
    showLockIcon: false, //lock required in colorAreaSwatch to lock the position of color, done in artoffloors
    specificMaterialForSpecificFolder: [], //done for jaipur,[["Hand Knotted", [0, 1, 2]],["Hand Tufted", [0, 2, 3]]]
    hideColorCustomizationForSpecificFolder: [], //to hide colorcustomize for specific folder,done for perennials
    identifyMaterialSilk: "" //done for hollyhunt, denote corresponding silk percent for each material.eg: 0|100|0|0|100
  },
  language: {
    langOptions: [], //languages to allow switching //{ val: "en", text: "EN" } format
    translateNames: false,
    strings: {} //strings.names={roomname1:{en:"english roomname1", de: " german roomname"}} see ktm rugs
  },
  showOrdersheetOutside: false,
  showOrdersheetOutsideInJpeg: true,
  ordersheet: {
    panel: "right", //in linkoutdoor panel left
    showRugSizeInOrderForm: true,
    isContactNumberMandatory: false,
    isRugsizeMandatory: false,
    isNotesMandatory: false,
    checkMandatoryForRequestQuote: false,
    checkMandatoryForSaveOrdersheet: false,
    showTotalAreaInYards: false,
    showAreaInM2: false,
    showAreaInM2InOrdersheetOnly: false,
    currencyFormat: CurrencyFormat.NONE,
    populateRugSize: true, // width and height is left blank when it's false
    noRugSizeInPdfForBlankSizeInput: false, // when true, size in pdf will be blank if size input is blank
    addMaxCharacterInFormFields: [], //[{ fieldName:"Additional Instructions", count: 230}], to add max count text after form fields ( eg in obt vifloor)
    changeableUnits: true, // unit is always set to defaultUnit when it's false
    strInPrice: "SILK", //String to show instead of price eg: in hollyhunt, silk % (100% SILK) is shown instead of price ($100)
    autoPopulateFields: [], //["Shape"] list name of ordersheet field you need to populate dynamically and add condition in getCustomFieldValue() function in ordersheet/index file
    mandatoryCustomFields: [], //field names of custom fields that are mandatory
    readOnlyAutoPopulate: false,
    roundOffToFtOrCm: false,
    roundOffToNearestHalf: false,
    roundOffToNearest5: false,
    allowColorOnlyAfterSizeInput: false,
    closeAfterEmailSent: true, // true for allegro
    addedCustomFields: [], //add here if you require different inputfields like checkbox, dropdown except textfield.
    customRugQualities: [
      // { quality: "SuperFine Handloom", knots: 50, lines: 46 },
      // { quality: "100 Knot", knots: 42, lines: 32 },
      // { quality: "60 Knot", knots: 33, lines: 16 }
    ],
    sameWidNHgt: false, //if design has same wid and hgt, changing one should change other
    storeFieldsValueInLocal: [],
    getEmailDetailsFromSStorage: false, //gets request quote form details from session (eg in surya rugs)
    changeActualRugSize: false, // true in makalustudiop
    repeatRugInArea: false, // true in kalatyphotosize
    designsToHideRugSizeFor: [],
    customConversion: {
      setCustomConversion: false, //for therugest, hiphome
      customInToCmFactor: 2.5 //(in to cm, 2.5cm=1inch )
    },
    upperCasedDesignNameinPDF: false,
    PDF_Filename: PDF_filenameRule.Default,
    dateFormat: dateFormat.MMDDYYYY,
    hasShapeOptions: null,
    // {
    //   options: ["Rectangle", "Square", "Circle"],
    //   defaultOptionIndex: 0,
    //   customFieldId:"jp_shape"
    // },
    showSummary: false, //show size, quality as text //done in jaipur
    hasMinAreaCriteria: 0, //set value of minimum area that is requored
    minAreaCriteriaMsg: "", //message to show when minimum area criteria is not met
    designImageZoom: 1,
    showRoomView: false,
    sameColorDifferentSymbol: false,
    showPileHeightNames: false,
    sendEmailDirectlyWithoutRequestQuote: false,
    sendDirectEmail: {
      name: "",
      email: "alita@explorug.net",
      phone: ""
    },
    showSendtoPrint: false,
    showTearSheet: false,
    defaultTearsheetIndex: 1,
    maxWidth: null, //set width and length maximum limit in ordersheet. done for tiledemo
    maxLength: null,
    redirectToLink: "", //redirect to link after fetch ordersheet,passes mode=json in fetchordersheet - done in makalutraumteppich
    showOrdersheetInNavbar: false, //show ordersheet button on top ,done in surya rugs
    showDoubleBoxForFtInch: false, //show separate boxes for ft and inch, done in kalatycustomsize
    changePdfSize: false,
    showSaveImageInsideOrdersheet: false, //true for kalatycustomsize to show save image inside ordersheet
    specificKnotForSpecificFolder: [], //done for makalu, specificKnotForSpecificFolder: [["1. Makalu Century", [0,1]],["2. Makalu Domus", [2]]]
    changeValueWithoutApplyButton: false,
    hasPostback: false,
    saveIconActAsOrdersheetSave: false //after enabling, remember to enable showOrdersheetOutside:true
  },
  email: {
    setCustomEmail: false,
    customerEmailBody: null,
    customerEmailSubject: null, //'test customer email subject',
    customerEmailFromAddress: null,
    companyEmailBody: null, //test company email body',
    companyEmailSubject: null, //'test company email subject',
    companyEmailFromAddress: null,
    AddCustomTextInDefaultEmail: false,
    customerEmailFrontText: null,
    companyEmailFrontText: null,
    footer: null
  },
  accountSpecific: {
    priceEstimation_davisLandings: {
      ordersheetPreviewButtonsLayout: false,
      checkKeyForPrice: false,
      priceEstimationKey: "12345",
      currency: "$",
      constants: {
        silkMaterialIndices: [1, 2],
        stocksilk: ["W010", "W011", "W013", "W019", "W020", "W021", "W108", "W328", "W711"]
      },
      variables: {
        RugTypes: {
          title: "Rug Types",
          options: ["Area Rug", "Strike off"]
        },
        RugTechniques: {
          title: "Rug Techniques",
          options: ["CYP Tuft", "Hand Tuft", "Handknot"]
        }
      }
    },
    oneAct: {
      hasOneActFolder: false,
      folder: "One Act"
    },
    hasCustomizationSteps: {
      hasStepwiseButtons: false,
      steps: [], // done in jaipurrugs
      defaultStepNum: 0,
      iconPath: ""
    },
    sendToApp: false,
    jaipurrugs: {
      showCodeUnderDesignName: false
    },
    isPaulig: false, // true in case of paulig1750,colorswatchPaulig is also triggered from this flag,
    dalyn: {
      isPOMandatory: false
    },
    tamarian: {
      enableCustomShare: false,
      sendStrikeOffInOrdersheet: false
    }
  },
  inhouseChanges: {
    //these changes are done for our internal apps like myroom flutter apps
    saveFunctionForFlutter: false
  },
  controller: {
    UItemplate: 1,
    Folder1Name: "ART ACRYLIC",
    Folder2Name: "CLASSIC",
    passJsonOnDesignChange: 1, // 0 for fullpath of 1x design , 1 for initDesign json
    passJsonOnRoomViewChange: 1,
    passJsonOnDetailViewChange: 1,
    showDesignsNRooms: 1,
    showTopViewOnly: false
  },
  roomsControl: [],
  showAllRoomsWhenNoRoomsSelected: true,
  checkRoomsControlForVariations: false,
  designSpecific: null,
  customDesignBaseUrl: null,
  corsCustomDesignUrl: false,
  showThumbnailForCustomDesignUrl: true, //false for not showing thumbnail while passing customdesignurl
  removeBgForCustomDesign: false,
  saveCustomDesignRoom: false,
  panelStateMobile: null,
  initDesignNameInUrl: false,
  explorugData: {
    passDesignNameAsDesignId: false,
    passQualityFromFolderName: false,
    sendOrigDesignColors: false,
    passTotalArea: false
  }
};
