import React, { useEffect, useRef, useState } from "react";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions
} from "../../../reducers/designlist.reducer";
import classNames from "classnames";
import ReactGA from "react-ga";
import Thumbnail from "../../molecules/Thumbnail";
import { SectionHeading } from "../../molecules/CustomCollapse";
import AppProvider from "../../../api/appProvider";
import {
  useDispatchDesignDetail,
  designDetailActions,
  useDesignDetailState
} from "../../../reducers/designdetails.reducer";
import { doesStringMatch } from "../../../utils/utils";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
import { processDesignPath, trimString } from "../../../utils/treeutils";
import {
  useDispatchVisualization,
  visualizationsActions
} from "../../../reducers/visualizations.reducer";
import { pageViews, useUiState } from "../../../reducers/mainui.reducer";
import AtIcon from "../../atoms/AtIcon";
import AtSizeDropdown from "../../atoms/AtSizeDropdown";

const DesignVariations = props => {
  const {
    initDesignColors,
    initDesignPath,
    initDesignVariation,
    initShapeVariation,
    triggeredDesign
  } = window.initialData;
  const { scrollIntoView } = props;
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const designDetailsState = useDesignDetailState();
  const dispatchVisState = useDispatchVisualization();
  const uiState = useUiState();
  const thumbContainerRef = useRef(null);
  const {
    similarDesignsFolder,
    selectedFile,
    activeVariation,
    activeShape,
    activeColorScheme
  } = designListState;
  const cyrFlags = window.flags.createYourRug;
  const isCustomSizeEnabled = cyrFlags.enableCustomSize.customizeSizeAndColor;
  const designSizes = cyrFlags.enableCustomSize.availableDesignSizes;
  const minmaxFt = cyrFlags.enableCustomSize.minMaxCustomSizeFt;

  const [dropDownValue, setDropdownValue] = useState(designSizes[0]);
  const [shapesCollapsed, setShapesCollapsed] = useState(
    !window.flags.designVariations.expandShapeVariations &&
      uiState.pageView !== pageViews.CREATEYOURRUG
  );
  const [colorSchemesCollapsed, setColorSchemesCollapsed] = useState(
    !window.flags.designVariations.expandColorVariations &&
      uiState.pageView !== pageViews.CREATEYOURRUG
  );
  const [variationsCollapsed, setVariationsCollapsed] = useState(
    !window.flags.designVariations.expandVariations && uiState.pageView !== pageViews.CREATEYOURRUG
  );

  const [similarColors, setSimilarColors] = useState({ files: [], children: [] });
  const [similarShapes, setSimilarShapes] = useState({ files: [], children: [] });
  const [similarDesigns, setSimilarDesigns] = useState({ files: [], children: [] });
  const setActiveVariation = variation => {
    dispatchDesignList({ type: designListActions.SET_ACTIVE_VARIATION, payload: variation });
  };
  const setActiveColorScheme = colorScheme => {
    dispatchDesignList({ type: designListActions.SET_ACTIVE_COLOR_SCHEME, payload: colorScheme });
  };
  const setactiveShape = shape => {
    dispatchDesignList({ type: designListActions.SET_ACTIVE_SHAPE, payload: shape });
  };
  const {
    containsOriginalDesign,
    putOriginalFirst,
    useOriginalName
  } = window.flags.designVariations;
  const getDesProps = defaultVar => {
    if (initDesignColors)
      return {
        ...defaultVar,
        designProps: { ...defaultVar.designProps, DesignColors: initDesignColors }
      };
    else return defaultVar;
  };
  const showOriginalDesign = () => {
    if (!activeColorScheme.id) return;
    setActiveColorScheme({});
    const detProps = !activeShape.id ? selectedFile : activeShape;
    let name = detProps.name;
    if (!doesStringMatch(name, selectedFile.name)) {
      name = `${selectedFile.name} ${trimString(name)}`;
    }
    handleThumbClick({ ...detProps, name });

    const similarShapes = similarDesignsFolder.children.find(
      item => item.name.toLowerCase() === "shapes"
    ) || { files: [] };
    setSimilarShapes(similarShapes);
  };

  useEffect(() => {
    let la = true;
    const loadThumbnails = async () => {
      if (similarDesignsFolder.files.length) {
        let resVar = similarDesignsFolder.files;
        //since we are rearranging the files, name should be set to null initially
        setSimilarDesigns({
          ...similarDesigns,
          files: resVar.map(item => ({ ...item, name: null }))
        });
        if (resVar.find(item => !item.thumbUrl))
          resVar = await AppProvider.getDesignThumbnails({
            designs: similarDesignsFolder.files
          });

        if (!la) return;
        const selectedFilNode = {
          ...selectedFile,
          name: window.flags.designVariations.sayOriginal
            ? strings.design.original
            : selectedFile.name
        };
        if (containsOriginalDesign) {
          if (putOriginalFirst) {
            const filterCondition = (a, b) => JSON.stringify(a) === JSON.stringify(b);
            const current = resVar.find(item =>
              filterCondition(item.designProps, selectedFile.designProps)
            );
            if (current) {
              const filteredResvar = resVar.filter(
                item => !filterCondition(item.designProps, selectedFile.designProps)
              );
              resVar = [current, ...filteredResvar];
              similarDesignsFolder.files = resVar;
            } else {
              resVar = [selectedFilNode, ...resVar];
            }
          }
        } else {
          resVar = [selectedFilNode, ...resVar];
        }
        resVar = resVar.filter(item => item.name != "Original");
        setSimilarDesigns({ ...similarDesigns, files: resVar });
        let defaultVar = containsOriginalDesign
          ? "name" in designListState.activeVariation
            ? designListState.activeVariation
            : resVar[0]
          : selectedFile;

        if (initDesignVariation && initDesignVariation !== "" && !activeVariation.id) {
          const varWProps = resVar.find(a => {
            return a.name.trim().toLowerCase() === initDesignVariation.toLowerCase();
          });
          //console.log("loadThumbnails -> varWProps", varWProps);
          if (varWProps) defaultVar = varWProps;
          if (!la) return;
          handleThumbClick(getDesProps(defaultVar));
        }

        if (window.flags.designVariations.preserveVariations && activeVariation.id) {
          const varWProps = resVar.find(a => a.fullPath === activeVariation.fullPath);
          if (varWProps) defaultVar = varWProps;
        }
        if (containsOriginalDesign) {
          handleThumbClick(getDesProps(defaultVar));
        }
        if (!resVar.find(file => file.fullPath === activeVariation.fullPath))
          setActiveVariation(defaultVar);
      } else {
        const similarColors = similarDesignsFolder.children.find(
          item => item.name.toLowerCase() === "colors"
        ) || { files: [] };
        let similarShapes = similarDesignsFolder.children.find(
          item => item.name.toLowerCase() === "shapes"
        ) || { files: [] };

        setSimilarColors({ ...similarColors, files: similarColors.files });
        setSimilarShapes({ ...similarShapes, files: similarShapes.files });
        let activeColorVariation = activeColorScheme;

        if (similarColors.files.length) {
          let resCol = similarColors.files;
          const ind = similarDesignsFolder.children.findIndex(
            item => item.name.toLowerCase() === "colors"
          );
          similarDesignsFolder.children[ind].files = resCol;
          if (similarColors.files.find(file => !file.thumbUrl))
            resCol = await AppProvider.getDesignThumbnails({ designs: similarColors.files });
          if (!la) return;
          similarDesignsFolder.children[ind].files = resCol;
          const similarColors_new = { ...similarColors, files: resCol };
          setSimilarColors(similarColors_new);
          let defaultVar = containsOriginalDesign ? resCol[0] : {};
          let activeVar = resCol.find(file => file.fullPath === activeColorVariation.fullPath);
          if (defaultVar.id) handleThumbClick(getDesProps(defaultVar));
          if (initDesignVariation) {
            let activeColorVar = resCol.find(
              file => file.name.toLowerCase() === initDesignVariation.toLowerCase()
            );
            if (activeColorVar) {
              activeVar = activeColorVar;
              activeColorVariation = activeColorVar;
              setActiveColorScheme(activeVar);
              handleThumbClick(getDesProps(activeVar));
            }
          }
          if (!activeVar) setActiveColorScheme(defaultVar);
        } else if (containsOriginalDesign && selectedFile.id) {
          handleThumbClick(getDesProps(selectedFile));
        }

        if (similarShapes.files.length) {
          if (activeColorVariation.id) {
            const actFolder = similarColors.children.find(
              item => item.name.substr(1).toLowerCase() === activeColorVariation.name.toLowerCase()
            );
            if (actFolder) similarShapes = { ...similarShapes, files: actFolder.files };
          }
          let resShap = similarShapes.files;

          const ind = similarDesignsFolder.children.findIndex(
            item => item.name.toLowerCase() === "shapes"
          );
          similarDesignsFolder.children[ind].files = resShap;
          if (similarShapes.files.find(file => !file.thumbUrl))
            resShap = await AppProvider.getDesignThumbnails({ designs: similarShapes.files });
          if (!la) return;
          similarDesignsFolder.children[ind].files = resShap;
          setSimilarShapes({ ...similarShapes, files: resShap });

          let defaultVar = containsOriginalDesign ? resShap[0] : {};
          let selectedShape = resShap.find(file => file.name === activeShape.name);

          if (initShapeVariation) {
            selectedShape = resShap.find(a => {
              return a.name.trim().toLowerCase() === initShapeVariation.toLowerCase();
            });
            if (selectedShape) {
              defaultVar = selectedShape;
              setactiveShape(defaultVar);
            }
          }

          setTimeout(() => {
            if (defaultVar.id) handleThumbClick(getDesProps(defaultVar));
          }, 1000);
          if (!selectedShape) setactiveShape(defaultVar);
        }
      }
    };
    loadThumbnails();
    return () => {
      la = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [similarDesignsFolder, initDesignVariation, initShapeVariation]);

  useEffect(() => {
    if (!similarColors.files.length && !similarDesigns.files.length) return;

    if (similarColors.files.length) {
      handleInitDesignVarChange({
        variationList: similarColors.files,
        onVariationClick: handleColorSchemeThumbClick,
        onOrigClick: showOriginalDesign,
        initDesignVariation,
        treatOrigDifferently: true
      });
    } else if (similarDesigns.files.length) {
      handleInitDesignVarChange({
        variationList: similarDesigns.files,
        onVariationClick: onSimilarDesignThumbClick,
        initDesignVariation,
        treatOrigDifferently: false
      });
    }
  }, [initDesignVariation]);

  const handleInitDesignVarChange = ({
    variationList,
    onVariationClick,
    onOrigClick,
    initDesignVariation,
    treatOrigDifferently = false
  }) => {
    if (variationList.length) {
      let isVariation = initDesignVariation && initDesignVariation !== "";
      isVariation = treatOrigDifferently
        ? isVariation && initDesignVariation !== "original"
        : isVariation;

      //console.log('variationList', variationList)
      if (isVariation) {
        const activeVar = variationList.find(a => {
          //console.log(a, initDesignVariation, getDesignPathInTitle(a.fullPath));

          return a.name && a.name.trim().toLowerCase() === initDesignVariation.toLowerCase();
        });
        if (activeVar) {
          onVariationClick(activeVar);
        }
      } else if (
        treatOrigDifferently &&
        initDesignVariation &&
        initDesignVariation.toLowerCase() === "original"
      ) {
        if (triggeredDesign && triggeredDesign !== "") {
          let designPath = processDesignPath(triggeredDesign);
          const sp = designPath.split(".").filter(item => item && item !== "");
          const ext = sp[sp.length - 1];
          if (ext.toLowerCase() !== "ctf" && ext.toLowerCase() !== "jpg") {
            designPath = `${designPath}.${window.InterfaceElements.IsJpeg ? "jpg" : "ctf"}`;
          }
          if (designPath.toLowerCase() === selectedFile.fullPath.toLowerCase()) {
            if (onOrigClick) onOrigClick();
          }
        }
      }
    }
  };
  const handleThumbClick = details => {
    const { selectedFile } = designListState;
    const { name: originalName } = designListState.selectedFile;
    const { designProps, name, fullPath } = details;
    if (isCustomSizeEnabled && window.flags.homeTemplate === pageViews.CREATEYOURRUG) {
      if (details.name.includes(" x ")) {
        const regex = /(\d+) x (\d+)/;
        const match = details.name.match(regex);
        const dimensions = match ? [parseInt(match[1]), parseInt(match[2])] : null;
        setDropdownValue(dimensions[0] + " x " + dimensions[1]);
        if (window.flags.defaultUnit == "cm") {
          document.getElementById("customSizeWidthCm").value = parseInt(dimensions[0]);
          document.getElementById("customSizeHeightCm").value = parseInt(dimensions[1]);
        } else {
          document.getElementById("customSizeWidthFt").value = parseInt(dimensions[0]);
          document.getElementById("customSizeHeightFt").value = parseInt(dimensions[1]);
        }
      }
    }
    let designDetails;
    if (initDesignPath !== fullPath) {
      designDetails = designProps;
    } else {
      designDetails = getDesProps(details).designProps;
    }
    ReactGA.ga("send", "event", {
      eventCategory: "View", //required
      eventAction: "Clicked Design Variation", //required
      eventLabel: `Clicked on ${fullPath}`
    });
    const useOrigName = fullPath === selectedFile.fullPath || useOriginalName;
    dispatchDesignDetails({
      type: designDetailActions.SET_DETAILS,
      payload: {
        designDetails,
        label: useOrigName ? originalName : name,
        fullpath: fullPath,
        originalName,
        setCustomConversion: window.flags.ordersheet.customConversion.setCustomConversion || false,
        customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
      }
    });
    if (window.flags.roomsControl.length && window.flags.checkRoomsControlForVariations) {
      updateRoomsList(fullPath);
    }
  };
  const customHandleThumbClick = async details => {
    if (designDetailsState.customSize.x == 0 || designDetailsState.customSize.y == 0) {
      handleThumbClick(details);
      return;
    }
    const { name: originalName } = designListState.selectedFile;
    const { name, fullPath } = details;
    const { x, y, closestName } = designDetailsState.customSize;
    const newname = replaceLastDimension(
      name,
      `${convertToNameForServer(designDetailsState.customSize.x)} x ${convertToNameForServer(
        designDetailsState.customSize.y
      )}`
    );

    await AppProvider.fetchCustomSizedDesign({
      widthRatio: x / parseInt(closestName.match(/(\d+)\s*x/)),
      heightRatio: y / parseInt(closestName.split(" x ")[1]),
      file: replaceLastDimension(fullPath, closestName.match(/\d+\s*x\s*\d+/)[0]),
      newname: newname
    }).then(async res => {
      dispatchDesignDetails({
        type: designDetailActions.SET_CUSTOM_SIZE,
        payload: { ...designDetailsState.customSize, currentCustomSizeFilename: res }
      });
      await AppProvider.getDesignThumbnails({
        designs: [{ fullPath: res }]
      }).then(async res => {
        dispatchDesignDetails({
          type: designDetailActions.SET_DETAILS,
          payload: {
            designDetails: res[0].designProps,
            label: details.name,
            fullpath: res[0].fullPath,
            originalName: originalName,
            setCustomConversion:
              window.flags.ordersheet.customConversion.setCustomConversion || false,
            customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
          }
        });
      });
    });
  };
  const collapseButtonClassNames = length => {
    const compareLength = length;
    // window.flags.designVariations.containsOriginalDesign
    // ? length - 1
    // : length;
    return classNames("at-collapse-button", {
      "has-morethan-3-children": compareLength > 3 && compareLength < 5,
      "has-3-children": compareLength <= 3
    });
  };

  const updateRoomsList = fullpath => {
    dispatchVisState({
      type: visualizationsActions.FILTER_ILLUSTRATIONS_FOR_DESIGN,
      payload: fullpath
    });
  };

  const handleColorSchemeThumbClick = (colorScheme, similarColorsArr) => {
    const similarColorsValues = similarColorsArr ? similarColorsArr : similarColors;
    if (activeColorScheme.fullpath === colorScheme.fullPath || !colorScheme.fullPath) return;
    const actFolder = similarColorsValues.children.find(
      item => item.name.substr(1).toLowerCase() === colorScheme.name.toLowerCase()
    );
    AppProvider.getDesignThumbnails({ designs: actFolder.files }).then(shapes => {
      setSimilarShapes({ ...similarShapes, files: shapes });
      const shape = shapes.find(item => item.name === activeShape.name);
      if (shape) setactiveShape(shape);
    });

    if (!activeShape.id) {
      let name = colorScheme.name;
      if (!doesStringMatch(name, selectedFile.name)) {
        name = `${selectedFile.name} ${trimString(name)}`;
      }
      handleThumbClick({ ...colorScheme, name });
    } else {
      if (actFolder) {
        const act = actFolder.files.find(item => item.name === activeShape.name);
        if (!act) return;
        AppProvider.getDesignThumbnails({ designs: [act] }).then(its => {
          const it = its[0];
          let colorSchemeName = colorScheme.name;
          if (!doesStringMatch(colorScheme.name, selectedFile.name)) {
            colorSchemeName = `${colorSchemeName} ${trimString(colorSchemeName)}`;
          }
          let name;
          let shapeSchemeName = activeShape.name;
          if (!doesStringMatch(shapeSchemeName, selectedFile.name)) {
            name = `${colorSchemeName} ${trimString(shapeSchemeName)}`;
          } else {
            name = trimString(shapeSchemeName.replace(selectedFile.name, colorSchemeName));
          }
          if (isCustomSizeEnabled) {
            customHandleThumbClick({ ...it, name });
          } else {
            handleThumbClick({ ...it, name });
          }
        });
      }
    }
    setActiveColorScheme(colorScheme);
  };
  const onSimilarDesignThumbClick = similarDesign => {
    if (!similarDesign.designProps || similarDesign.fullPath === activeVariation.fullPath) return;
    setActiveVariation(similarDesign);
    let name = similarDesign.name;
    if (!doesStringMatch(name, selectedFile.name)) {
      name = `${selectedFile.name} ${trimString(name)}`;
    }
    handleThumbClick({ ...similarDesign, name });
  };

  const checkForScrollInCYR = view => {
    if (window.flags.homeTemplate === pageViews.CREATEYOURRUG) {
      scrollIntoView(view);
    }
  };
  const replaceLastDimension = (filename, replace) => {
    // Match the last occurrence of digits followed by 'x' and more digits
    let regex = /(\d+\s*x\s*\d+)(?![\s\S]*\1)/;
    return filename.replace(regex, replace);
  };

  const formatFloatValue = floatValue => {
    return floatValue % 1 === 0 ? floatValue.toFixed(0) : floatValue.toFixed(1);
  };

  const convertToNameForServer = floatValue => {
    const feet = Math.floor(floatValue);
    const inches = Math.round((floatValue - feet) * 12);
    if (inches === 0) {
      return `${feet}`;
    } else {
      return `${feet}-${inches}`;
    }
  };

  const handleSizeChange = event => {
    setDropdownValue(event.target.value);
    const getSelectedSize = document.querySelector(`.thumb-title[title="${event.target.value}"]`);
    if (getSelectedSize) {
      getSelectedSize.click();
    }
  };

  const handleCustomSize = async () => {
    let rawInput1, rawInput2;
    if (window.flags.defaultUnit == "cm") {
      rawInput1 = parseInt(document.getElementById("customSizeWidthCm").value);
      rawInput2 = parseInt(document.getElementById("customSizeHeightCm").value);
    } else {
      let widthFt = document.getElementById("customSizeWidthFt").value;
      let heightFt = document.getElementById("customSizeHeightFt").value;
      let widthElem = document.getElementById("customSizeWidthIn");
      let heightElem = document.getElementById("customSizeHeightIn");
      let widthIn = widthElem.value;
      let heightIn = heightElem.value;
      rawInput1 = parseInt(widthFt) + widthIn / 12;
      rawInput2 = parseInt(heightFt) + heightIn / 12;
      if (rawInput1 > minmaxFt.width[1]) {
        rawInput1 = parseInt(rawInput1);
        widthElem.value = 0;
      }
      if (rawInput2 > minmaxFt.height[1]) {
        rawInput2 = parseInt(rawInput2);
        heightElem.value = 0;
      }
    }
    if (isNaN(rawInput1) || isNaN(rawInput2) || rawInput1 == 0 || rawInput2 == 0) return;
    const parsedInput1 = formatFloatValue(rawInput1);
    const parsedInput2 = formatFloatValue(rawInput2);
    const elements = document.querySelectorAll(
      `.thumb-title[title*="${parsedInput1} x ${parsedInput2}"]`
    );
    if (elements.length !== 0) {
      elements[0].click();
      return;
    }
    checkForScrollInCYR(cyrFlags.scrollToDesignViewAfterSimilarShapesOnClick);
    // Calculate the closest area
    let closestDesign = null;
    let minDiff = Infinity;
    const filteredDesigns = similarShapes.files.filter(design => design.name.includes(" x "));
    filteredDesigns.forEach(design => {
      const sourceWidth = parseInt(design.name.split("x")[0].match(/\d+/g)[0]);
      const sourceHeight = parseInt(design.name.split("x")[1]);
      if (rawInput1 <= sourceWidth && rawInput2 <= sourceHeight) {
        const diff = Math.abs(rawInput1 - sourceWidth) + Math.abs(rawInput2 - sourceHeight);
        if (diff < minDiff) {
          minDiff = diff;
          closestDesign = design;
        }
      }
    });

    const newname = replaceLastDimension(
      closestDesign.name,
      `${convertToNameForServer(parsedInput1)} x ${convertToNameForServer(parsedInput2)}`
    );
    await AppProvider.fetchCustomSizedDesign({
      widthRatio:
        parseFloat(rawInput1).toFixed(1) /
        parseInt(closestDesign.name.split(" x ")[0].match(/\d+/g)[0]),
      heightRatio: parseFloat(rawInput2).toFixed(1) / parseInt(closestDesign.name.split(" x ")[1]),
      file: closestDesign.fullPath,
      newname: newname
    }).then(async res => {
      dispatchDesignDetails({
        type: designDetailActions.SET_CUSTOM_SIZE,
        payload: {
          x: parsedInput1,
          y: parsedInput2,
          closestName: closestDesign.name,
          currentCustomSizeFilename: res
        }
      });
      await AppProvider.getDesignThumbnails({
        designs: [{ fullPath: res }]
      }).then(async res => {
        dispatchDesignDetails({
          type: designDetailActions.SET_DETAILS,
          payload: {
            designDetails: res[0].designProps,
            label: closestDesign.name,
            fullpath: res[0].fullPath,
            originalName: selectedFile.name,
            setCustomConversion:
              window.flags.ordersheet.customConversion.setCustomConversion || false,
            customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
          }
        });
      });
    });
    closestDesign = null;
  };

  return (
    <>
      {similarDesignsFolder.files.length ? (
        <div className="at-design-variations" id="atDesignVariations">
          <SectionHeading
            className="at-design-variations-heading at-design-variations-similardesigns"
            title={strings.design.designVariations}
            additional={
              <AtButton
                className={collapseButtonClassNames(similarDesignsFolder.files.length)}
                text={variationsCollapsed ? strings.tools.showMore : strings.tools.showLess}
                minimal
                onClick={() => setVariationsCollapsed(!variationsCollapsed)}
              />
            }
          />
          <div
            className={classNames("at-color-schemes", "at-design-variations-similardesigns", {
              collapsed: variationsCollapsed
            })}
          >
            {similarDesigns.files.map((similarDesign, i) => (
              <Thumbnail
                active={similarDesign.fullPath === activeVariation.fullPath}
                thumb={{
                  ...similarDesign,
                  name: similarDesign.name
                    ? similarDesign.name.replace(selectedFile.name, "").trim()
                    : ""
                }}
                key={i}
                onThumbnailClick={e => {
                  checkForScrollInCYR(cyrFlags.scrollToDesignViewAfterVariationOnClick);
                  onSimilarDesignThumbClick(similarDesign);
                }}
              />
            ))}
          </div>
        </div>
      ) : similarDesignsFolder.children.length ? (
        <div className="at-design-variations" id="atDesignVariations">
          {similarColors.files.length ? (
            <>
              <SectionHeading
                className="at-design-variations-heading at-design-variations-colors"
                title={strings.design.colorSchemes}
                additional={
                  <AtButton
                    className={collapseButtonClassNames(similarColors.files.length)}
                    text={colorSchemesCollapsed ? strings.tools.showMore : strings.tools.showLess}
                    minimal
                    onClick={() => setColorSchemesCollapsed(!colorSchemesCollapsed)}
                  />
                }
              />
              <div
                className={classNames("at-color-schemes", "at-design-variations-colors", {
                  collapsed: colorSchemesCollapsed
                })}
              >
                {cyrFlags.enableCustomSize.customizeColorBox &&
                  window.flags.homeTemplate === pageViews.CREATEYOURRUG && (
                    <div>
                      <div
                        className="thumb-image-container portrait selectColorInSimilarColorsTab"
                        style={{ cursor: "pointer" }}
                        onClick={() => checkForScrollInCYR(".cyr-detailView")}
                      >
                        <div className="plusIconDiv">
                          <AtIcon icon="plus" size={5} />
                          <p className="customizeColorDiv">Customize your own color</p>
                        </div>
                      </div>
                      <span className="thumb-title" title="U 2" style={{ visibility: "hidden" }}>
                        <p>U 2 </p>
                      </span>
                    </div>
                  )}
                {!containsOriginalDesign && (
                  <Thumbnail
                    className="original_colorScheme"
                    active={!activeColorScheme.id}
                    thumb={{
                      ...selectedFile,
                      name: strings.design.original
                    }}
                    onThumbnailClick={() => {
                      checkForScrollInCYR(cyrFlags.scrollToDesignViewAfterSimilarColorOnClick);
                      showOriginalDesign();
                    }}
                  />
                )}

                {similarColors.files.map((colorScheme, i) => (
                  <Thumbnail
                    active={colorScheme.fullPath === activeColorScheme.fullPath}
                    thumb={{
                      ...colorScheme,
                      name: trimString(colorScheme.name.replace(selectedFile.name, ""))
                    }}
                    key={i}
                    onThumbnailClick={e => {
                      checkForScrollInCYR(cyrFlags.scrollToDesignViewAfterSimilarColorOnClick);
                      handleColorSchemeThumbClick(colorScheme);
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
          {similarShapes.files.length ? (
            <>
              <SectionHeading
                className="at-design-variations-heading at-design-variations-shapes"
                title={strings.design.standardShapes}
                additional={
                  <AtButton
                    className={collapseButtonClassNames(similarShapes.files.length)}
                    text={shapesCollapsed ? strings.tools.showMore : strings.tools.showLess}
                    minimal
                    onClick={() => setShapesCollapsed(!shapesCollapsed)}
                  />
                }
              />
              <div
                className={classNames("at-color-schemes", "at-design-variations-shapes", {
                  collapsed: shapesCollapsed
                })}
                ref={thumbContainerRef}
              >
                {isCustomSizeEnabled && window.flags.homeTemplate === pageViews.CREATEYOURRUG && (
                  <AtSizeDropdown
                    handleCustomSize={handleCustomSize}
                    handleSizeChange={handleSizeChange}
                    dropDownValue={dropDownValue}
                    designSizes={designSizes}
                  />
                )}
                {!containsOriginalDesign && (
                  <Thumbnail
                    active={!activeShape.id}
                    thumb={
                      activeColorScheme.id
                        ? {
                            ...activeColorScheme,
                            name: strings.design.original
                          }
                        : {
                            ...selectedFile,
                            name: strings.design.original
                          }
                    }
                    onThumbnailClick={e => {
                      if (!activeShape.id) return;
                      checkForScrollInCYR(cyrFlags.scrollToDesignViewAfterSimilarShapesOnClick);
                      setactiveShape({});
                      const detProps = !activeColorScheme.id ? selectedFile : activeColorScheme;
                      let name = detProps.name;
                      if (!doesStringMatch(name, selectedFile.name)) {
                        name = `${selectedFile.name} ${trimString(name)}`;
                      }
                      handleThumbClick({ ...detProps, name });
                    }}
                  />
                )}

                {similarShapes.files.map((shapeScheme, i) => (
                  <Thumbnail
                    active={shapeScheme.fullPath === activeShape.fullPath}
                    thumb={{
                      ...shapeScheme,
                      name: trimString(shapeScheme.name.replace(selectedFile.name, ""))
                    }}
                    key={i}
                    onThumbnailClick={e => {
                      if (
                        window.flags.homeTemplate == pageViews.HOME &&
                        (!shapeScheme.designProps || shapeScheme.fullPath === activeShape.fullPath)
                      )
                        return;
                      checkForScrollInCYR(cyrFlags.scrollToDesignViewAfterSimilarShapesOnClick);
                      dispatchDesignDetails({
                        type: designDetailActions.SET_CUSTOM_SIZE,
                        payload: {
                          x: 0,
                          y: 0
                        }
                      });
                      const actFolder = similarShapes.children.find(
                        item => item.name.substr(1).toLowerCase() === shapeScheme.name.toLowerCase()
                      );
                      if (!activeColorScheme.id) {
                        let name = shapeScheme.name;
                        if (!doesStringMatch(name, selectedFile.name)) {
                          name = `${selectedFile.name} ${trimString(name)}`;
                        }
                        handleThumbClick({ ...shapeScheme, name });
                      } else {
                        if (actFolder) {
                          const act = actFolder.files.find(
                            item => item.name === activeColorScheme.name
                          );
                          if (!act) return;
                          AppProvider.getDesignThumbnails({ designs: [act] }).then(its => {
                            const it = its[0];
                            let colorSchemeName = activeColorScheme.name;
                            if (!doesStringMatch(colorSchemeName, selectedFile.name)) {
                              colorSchemeName = `${colorSchemeName} ${trimString(colorSchemeName)}`;
                            }
                            let name;
                            let shapeSchemeName = shapeScheme.name;
                            if (!doesStringMatch(shapeSchemeName, selectedFile.name)) {
                              name = `${colorSchemeName} ${trimString(shapeSchemeName)}`;
                            } else {
                              name = trimString(
                                shapeSchemeName.replace(selectedFile.name, colorSchemeName)
                              );
                            }
                            handleThumbClick({ ...it, name });
                          });
                        }
                      }
                      setactiveShape(shapeScheme);
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DesignVariations;
