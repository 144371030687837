import { colorNumberModes } from "../components/pages/EntryPage/defaultflags";
import React from "react";
import { MD5 } from "../utils/md5";
import { getPathOffile } from "../utils/arrayUtils";
import { convertUnit_Arr } from "../utils/utils";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SET_DETAILS = "SET_DETAILS";
const SET_RUG_PHYSICAL_SIZE = "SET_RUG_PHYSICAL_SIZE";

const SET_TILE_DETAILS = "SET_TILE_DETAILS";
const SET_DETAILS_ONLY = "SET_DETAILS_ONLY";
const SELECT_DESIGN_COLOR = "SELECT_DESIGN_COLOR";
const CHANGE_COLOR = "CHANGE_COLOR";
const SET_DESIGN_PATH = "SET_DESIGN_PATH";
const CHANGE_TEXTURE = "CHANGE_DESIGN_TEXTURE";
const CHANGE_COLOR_TEXTURE = "CHANGE_DESIGN_COLOR_TEXTURE";
const CHANGE_COLOR_PALETTE = "CHANGE_COLOR_PALETTE";
const UNDO_COLOR_SELECTION = "UNDO_COLOR_SELECTION";
const REDO_COLOR_SELECTION = "REDO_COLOR_SELECTION";
const RESET_COLOR_SELECTION = "RESET_COLOR_SELECTION";
const SET_ORDER_PROPERTIES = "SET_ORDER_PROPERTIES";
const CHANGE_PILE = "CHANGE_PILE";
const CHANGE_COLOR_SCHEME = "CHANGE_COLOR_SCHEME";
const SET_LOADING = "SET_LOADING";
const SET_CUSTOMIZATION_FLAG = "SET_CUSTOMIZATION_FLAG";
const TOGGLE_COLOR_CARVING = "TOGGLE_COLOR_CARVING";
const MIX_PLY = "MIX_PLY";
const CHANGE_YARN_COLOR = "CHANGE_YARN_COLOR";
const SELECT_YARN = "SELECT_YARN";
const CHANGE_YARN_MATERIAL = "CHANGE_YARN_MATERIAL";
const SET_CUSTOSCOUT_COORDS = "SET_CUSTOSCOUT_COORDS";
const TOOGLE_CUSTOSCOUT = "TOOGLE_CUSTOSCOUT";
const SET_CUSTOSCOUT_ID_TO_HIDE = "SET_CUSTOSCOUT_ID_TO_HIDE"; //In cyr the area swatch shows in both design and room stage area.
const SET_AVAIL_SIZES = "SET_AVAIL_SIZES";
const SELECT_ILLU_SIZE = "SELECT_ILLU_SIZE";
const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";
const SET_CUSTOM_FIELDS_LOADING = "SET_CUSTOM_FIELDS_LOADING";
const SET_DESIGNCOLORS = "SET_DESIGNCOLORS";
const CHANGE_HASH = "CHANGE_HASH";
const SET_DESIGN_VIEW_ZOOM = "SET_DESIGN_VIEW_ZOOM";
const SET_DESIGN_VIEW_ZOOM_JPEG = "SET_DESIGN_VIEW_ZOOM_JPEG";
const SET_URL_QR = "SET_URL_QR";
const SET_LOCKED_COLOR_INDEX_ARRAY = "SET_LOCKED_COLOR_INDEX_ARRAY";
const SET_CUSTOM_SIZE = "SET_CUSTOM_SIZE";

const designDetailActions = {
  SET_DETAILS,
  SET_DETAILS_ONLY,
  SET_DESIGNCOLORS,
  SET_RUG_PHYSICAL_SIZE,
  CHANGE_COLOR,
  SELECT_DESIGN_COLOR,
  SET_DESIGN_PATH,
  CHANGE_TEXTURE,
  CHANGE_COLOR_PALETTE,
  UNDO_COLOR_SELECTION,
  REDO_COLOR_SELECTION,
  RESET_COLOR_SELECTION,
  SET_ORDER_PROPERTIES,
  CHANGE_PILE,
  CHANGE_COLOR_TEXTURE,
  CHANGE_COLOR_SCHEME,
  SET_LOADING,
  SET_TILE_DETAILS,
  SET_CUSTOMIZATION_FLAG,
  TOGGLE_COLOR_CARVING,
  MIX_PLY,
  CHANGE_YARN_COLOR,
  CHANGE_YARN_MATERIAL,
  SELECT_YARN,
  SET_CUSTOSCOUT_COORDS,
  TOOGLE_CUSTOSCOUT,
  SET_CUSTOSCOUT_ID_TO_HIDE,
  SET_AVAIL_SIZES,
  SELECT_ILLU_SIZE,
  SET_CUSTOM_FIELDS,
  SET_CUSTOM_FIELDS_LOADING,
  CHANGE_HASH,
  SET_DESIGN_VIEW_ZOOM,
  SET_DESIGN_VIEW_ZOOM_JPEG,
  SET_URL_QR,
  SET_LOCKED_COLOR_INDEX_ARRAY,
  SET_CUSTOM_SIZE
};
const designDetailState = {
  loading: true,
  designDetails: {},
  designName: null,
  originalName: null,
  currentDesignPath: null,
  designDimsOrig: null,
  fullpath: null,
  selectedColor: -1,
  customizationFlag: false,
  history: [],
  future: [],
  undoHistory: [],
  totalKnots: null,
  tileDetails: null,
  hash: null,
  designViewZoom: 3,
  designViewZoomJpeg: 1,
  encodedUrl: null,
  updateDesignTiles: null,
  updateNormapTiles: null,
  yarnIndex: -1,
  custoScout: {
    show: false,
    designPoint: null,
    containerPoint: null,
    custoScoutIdforCyr: "",
    clickedDivElement: ""
  },
  carvingColorsIndex: [],
  customFields: {
    loading: false,
    fields: null
  },
  lockedColorIndexArray: [], //during shuffling don't change the positions of this array, done in artoffloors
  customSize: {
    x: 0,
    y: 0,
    closestName: "",
    currentCustomSizeFilename: ""
  }
};
const getVariationFolder = fullpath => {
  if (!fullpath || fullpath === "") return fullpath;
  let foldername = fullpath.split("/").pop();
  foldername = foldername.substr(0, foldername.lastIndexOf("."));

  if (fullpath.lastIndexOf("/.") >= 0) {
    const sp = fullpath.split("/");
    foldername = sp[sp.length - 2].replace(".", "");
  }
  return foldername;
};

const designDetailReducer = (state, action) => {
  switch (action.type) {
    case SET_DETAILS:
      return setDesignDetails(state, action.payload);
    case SET_RUG_PHYSICAL_SIZE:
      return changeRugPhySize(state, action.payload);
    case SET_TILE_DETAILS:
      return setTileDetails(state, action.payload);
    case SET_URL_QR:
      return { ...state, encodedUrl: action.payload };
    case SELECT_DESIGN_COLOR:
      return {
        ...state,
        selectedColor: action.payload,
        custoScout: { ...state.custoScout, show: state.custoScout.show }
      };
    case CHANGE_COLOR:
      return changeDesignColor(state, action.payload);
    case CHANGE_COLOR_PALETTE:
      return changeColorPalette(state, action.payload);

    case UNDO_COLOR_SELECTION:
      if (!state.history.length) return state;
      return undoDesignColor(state);
    case REDO_COLOR_SELECTION:
      if (!state.future.length) return state;
      return redoDesignColor(state);
    case RESET_COLOR_SELECTION:
      if (!state.history.length) return state;
      window.sessionStorage.removeItem("DesignColors");
      return {
        ...state,
        designDetails: state.history[0].designDetails,
        updateDesignTiles: null,
        updateNormapTiles: null,
        hash: state.history[0].hash,
        history: []
      };
    case SET_DESIGN_PATH:
      return { ...state, currentDesignPath: action.payload };
    case CHANGE_TEXTURE:
      return { ...state };
    case CHANGE_COLOR_TEXTURE:
      return changeColorMaterial(state, action.payload);
    case CHANGE_PILE:
      return changeColorPile(state, action.payload);
    case CHANGE_COLOR_SCHEME:
      return changeColorScheme(state, action.payload);
    case SET_LOADING:
      if (state.loading === action.payload) return state;
      return { ...state, loading: action.payload };
    case SET_CUSTOMIZATION_FLAG:
      return { ...state, customizationFlag: action.payload };
    case TOGGLE_COLOR_CARVING:
      return toggleCarving(state, action.payload);
    case MIX_PLY:
      return mixPly(state, action.payload);
    case CHANGE_YARN_COLOR:
      return changeYarnColor(state, action.payload);
    case CHANGE_YARN_MATERIAL:
      return changeYarnMaterial(state, action.payload);
    case SELECT_YARN:
      return {
        ...state,
        yarnIndex: action.payload.yarnIndex,
        selectedColor: action.payload.colorIndex
      };
    case SET_CUSTOSCOUT_COORDS:
      return {
        ...state,
        custoScout: {
          ...state.custoScout,
          ...action.payload
        }
      };
    case TOOGLE_CUSTOSCOUT:
      return { ...state, custoScout: { ...state.custoScout, show: action.payload } };
    case SET_CUSTOSCOUT_ID_TO_HIDE:
      return { ...state, custoScout: { ...state.custoScout, custoScoutIdforCyr: action.payload } };
    case SET_CUSTOM_FIELDS_LOADING:
      return { ...state, customFields: { ...state.customFields, loading: !!action.payload } };
    case SET_CUSTOM_FIELDS:
      return { ...state, customFields: { ...state.customFields, fields: action.payload } };
    case SET_CUSTOM_SIZE:
      return { ...state, customSize: action.payload };
    case SET_DESIGN_VIEW_ZOOM:
      return { ...state, designViewZoom: action.payload };
    case SET_DESIGN_VIEW_ZOOM_JPEG:
      return { ...state, designViewZoomJpeg: action.payload };
    case SET_DESIGNCOLORS:
      return setDesignColors(state, action.payload);
    case CHANGE_HASH:
      return {
        ...state,
        hash: generateHash(action.payload, Math.round(Math.random(100000) * 100000))
      };
    case SET_LOCKED_COLOR_INDEX_ARRAY:
      return { ...state, lockedColorIndexArray: action.payload };
    default:
      return state;
  }
};
const setDesignColors = (state, payload) => {
  let newDesignDetails = { ...state.designDetails, DesignColors: payload };

  let designDetailsState = {
    ...state,
    designDetails: newDesignDetails,
    colorNumbers: updateColorNumbers(newDesignDetails.DesignColors),
    hash: generateHash(newDesignDetails)
  };
  return designDetailsState;
};
const setDesignDetails = (state, payload) => {
  const {
    designDetails: designDetailsPayload,
    fullpath,
    hash,
    customizationFlag,
    label,
    originalName,
    setCustomConversion = false,
    customInToCmFactor
  } = payload;
  const details = normalizeUnit({
    ...designDetailsPayload,
    defaultUnit: window.flags.defaultUnit,
    setCustomConversion,
    customInToCmFactor
  });
  let designColorsPalette = designDetailsPayload.DesignColors || [];
  // if (window.flags.applyColorPaletteinVariations) {
  //   window.sessionStorage.removeItem("ParentFolder");
  //   window.sessionStorage.removeItem("DesignColors");
  // }
  //if session storage ma xa vane designdetains.DesignColors && getPathOffile (state.fullpath)=== (session ko path)
  if (window.flags.applyColorPaletteinSameFolder) {
    const ParentFolder = window.sessionStorage.getItem("ParentFolder");
    const DesignColors = window.sessionStorage.getItem("DesignColors");
    if (getPathOffile(fullpath) === ParentFolder && DesignColors) {
      designColorsPalette = JSON.parse(DesignColors);
    }
  }
  if (window.flags.applyColorPaletteinVariations) {
    const DesignColors = window.sessionStorage.getItem("DesignColors");
    const CurrentVariationFolder = window.sessionStorage.getItem("CurrentVariationFolder");

    if (getVariationFolder(fullpath) === CurrentVariationFolder && DesignColors) {
      designColorsPalette = JSON.parse(DesignColors);
    } else if (DesignColors && getVariationFolder(fullpath) !== CurrentVariationFolder) {
      window.sessionStorage.removeItem("DesignColors");
    }
  }
  const designDetails = {
    ...designDetailsPayload,
    ...details,
    OrderProperties: { QualityIndex: window.flags.defaultQualityIndex },
    DesignColors: designColorsPalette
  };
  let history = [];
  if (
    designDetailsPayload.DesignColors !== designColorsPalette &&
    (window.flags.applyColorPaletteinVariations || window.flags.applyColorPaletteinSameFolder)
  ) {
    // History states should reset to [], normally, but when color palette should be passed to other variations / designs of same folder, the history state should have original color in it's array.
    // When a design Variation is changed, the original state should have size of current variation and colors of its original state.
    //Therefore, the Original state i.e first element of the history array should be similar to state of the newly selected design but the color should be that of original state.

    //Setting new history state
    const { updateDesignTiles, updateNormapTiles, hash } = state;
    const newHistoryState = { designDetails, updateDesignTiles, updateNormapTiles, hash };

    //Getting Original color of the design
    const origColors =
      state.history && state.history.length ? state.history[0].designDetails.DesignColors : null;

    if (origColors) {
      //if orig colors exist, first element should have orig colors and physical width, height...other parameters equal to new selected design i.e. new history state
      const firstHistoryState = {
        ...newHistoryState,
        designDetails: { ...newHistoryState.designDetails, DesignColors: origColors }
      };
      history = [firstHistoryState, newHistoryState];
    }
  }
  return {
    ...state,
    designDetails,
    history: history,
    totalKnots: designDetails.DesignColors.map(c => c.Knots).reduce((a, b) => a + b, 0),
    colorNumbers: updateColorNumbers(designDetails.DesignColors),
    selectedColor: -1,
    currentDesignPath: customizationFlag ? null : "",
    fullpath: fullpath,
    designName: label,
    originalName: originalName ? originalName : label,
    updateDesignTiles: null,
    updateNormapTiles: null,
    customizationFlag: !!customizationFlag,
    hash: hash ? hash : generateHash(designDetails, fullpath),
    carvingColorsIndex: getCarvingColorNumbers(designDetails.DesignColors),
    designDimsOrig: {
      Width: designDetails.Width,
      Height: designDetails.Height,
      PhysicalWidth: designDetails.PhysicalWidth,
      PhysicalHeight: designDetails.PhysicalHeight
    }
  };
};

const changeRugPhySize = (state, payload) => {
  const widRatio = state.designDetails.Width / state.designDetails.PhysicalWidth;
  const hgtRatio = state.designDetails.Height / state.designDetails.PhysicalHeight;
  const PhysicalWidth = payload.PhysicalWidth
    ? payload.PhysicalWidth
    : state.designDetails.PhysicalWidth;
  const PhysicalHeight = payload.PhysicalHeight
    ? payload.PhysicalHeight
    : state.designDetails.PhysicalHeight;
  const Width = Math.round(PhysicalWidth * widRatio);
  const Height = Math.round(PhysicalHeight * hgtRatio);
  const designDetails = {
    ...state.designDetails,
    PhysicalWidth,
    Width,
    Height,
    PhysicalHeight
  };
  const hash = payload.hash ? payload.hash : generateHash(designDetails, state.fullpath);
  return {
    ...state,
    designDetails,
    hash
  };
};
const setColorsInSession = (DesignColors, fullpath) => {
  // const DesignColors = DesignColors;
  const ParentFolder = getPathOffile(fullpath);
  const currentVariationFolder = getVariationFolder(fullpath);
  window.sessionStorage.setItem("ParentFolder", ParentFolder);
  window.sessionStorage.setItem("CurrentVariationFolder", currentVariationFolder);
  window.sessionStorage.setItem("DesignColors", JSON.stringify(DesignColors));
};
const changeDesignColor = (state, payload) => {
  const { designDetails, selectedColor, updateDesignTiles, updateNormapTiles, hash } = state;
  const colorDetail = payload;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  const colorMaterial = parseInt(colorDetail.Texture);
  d.DesignColors[selectedColor].Color = colorDetail.Color;
  d.DesignColors[selectedColor].ColorName = colorDetail.ColorName;
  d.DesignColors[selectedColor].AssociatedMat = colorDetail.Texture;
  if (colorMaterial !== -1) {
    d.DesignColors[selectedColor].Material = colorMaterial;
    d.DesignColors[selectedColor].YarnDetails.forEach(yarn => {
      yarn.Material = colorMaterial;
    });
  }
  d.DesignColors[selectedColor].YarnDetails[0].Color = colorDetail.Color;
  d.DesignColors[selectedColor].YarnDetails[0].ColorName = colorDetail.ColorName;
  d.DesignColors[selectedColor].YarnDetails[0].AssociatedMat = colorDetail.Texture;

  //set in session storage
  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    setColorsInSession(d.DesignColors, state.fullpath);
  }
  if (window.flags.colorAreaSwatch.sameColorLock) {
    const origDesignColors = state.history.length
      ? state.history[0].designDetails.DesignColors
      : designDetails.DesignColors;
    origDesignColors.forEach((color, index) => {
      if (index === selectedColor) return;
      if (color.YarnDetails[0].Color === origDesignColors[selectedColor].YarnDetails[0].Color) {
        d.DesignColors[index].Color = colorDetail.Color;
        d.DesignColors[index].ColorName = colorDetail.ColorName;
        d.DesignColors[index].YarnDetails[0].Color = colorDetail.Color;
        d.DesignColors[index].YarnDetails[0].ColorName = colorDetail.ColorName;
        d.DesignColors[index].YarnDetails[0].AssociatedMat = colorDetail.Texture;

        if (colorMaterial !== -1) {
          d.DesignColors[index].Material = colorMaterial;
          d.DesignColors[index].YarnDetails.forEach(yarn => {
            yarn.Material = colorMaterial;
          });
        }
      }
    });
  }
  return {
    ...state,
    designDetails: d,
    colorNumbers: updateColorNumbers(d.DesignColors),
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    customizationFlag: true,
    updateDesignTiles: { colorIndex: selectedColor },
    updateNormapTiles: null,
    hash: generateHash(d)
  };
};

const changeColorMaterial = (
  state,
  { material, materialName, colorIndex, isMaterialSameForAll }
) => {
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  d.DesignColors[colorIndex].Material = material;
  d.DesignColors[colorIndex].materialName = materialName;
  d.DesignColors[colorIndex].YarnDetails.forEach(yarn => {
    yarn.Material = material;
    yarn.MaterialName = materialName;
  });
  if (isMaterialSameForAll) {
    d.DesignColors.forEach(designColor => {
      designColor.Material = material;
      designColor.materialName = material;
      designColor.YarnDetails.forEach(yarn => {
        yarn.Material = material;
        yarn.MaterialName = materialName;
      });
    });
  }
  // d.DesignColors[colorIndex].YarnDetails[0].Material = material;

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    setColorsInSession(d.DesignColors, state.fullpath);
  }
  return {
    ...state,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    designDetails: d,
    colorNumbers: updateColorNumbers(d.DesignColors),
    selectedColor: colorIndex,
    customizationFlag: true,
    updateDesignTiles: { colorIndex },
    updateNormapTiles: null,
    hash: generateHash(d)
  };
};
const changeYarnColor = (state, payload) => {
  const { designDetails, yarnIndex, selectedColor, updateDesignTiles } = state;
  const { Color, ColorName } = payload;

  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  d.DesignColors[selectedColor].YarnDetails[yarnIndex] = {
    ...d.DesignColors[selectedColor].YarnDetails[yarnIndex],
    Color,
    ColorName
  };

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    setColorsInSession(d.DesignColors, state.fullpath);
  }
  const updatedDesignTiles = { colorIndex: selectedColor };
  return {
    ...state,
    designDetails: d,
    colorNumbers: updateColorNumbers(d.DesignColors),
    updateDesignTiles: updatedDesignTiles,
    updateNormapTiles: null,
    history: [...state.history, { designDetails, updateDesignTiles }],
    customizationFlag: true,
    hash: generateHash(d)
  };
};
const changeYarnMaterial = (state, payload) => {
  const { designDetails, yarnIndex, selectedColor, updateNormapTiles, hash } = state;
  const { material } = payload;

  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  d.DesignColors[selectedColor].YarnDetails[yarnIndex] = {
    ...d.DesignColors[selectedColor].YarnDetails[yarnIndex],
    Material: material
  };

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    setColorsInSession(d.DesignColors, state.fullpath);
  }
  const updateDesignTiles = { colorIndex: selectedColor };
  return {
    ...state,
    designDetails: d,
    colorNumbers: updateColorNumbers(d.DesignColors),
    updateDesignTiles,
    updateNormapTiles: null,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    customizationFlag: true,
    hash: generateHash(d)
  };
};
const mixPly = (state, payload) => {
  const { designDetails } = state;
  const { colorIndex, plyNumber } = payload;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  const { YarnDetails } = d.DesignColors[colorIndex];
  const { Color: firstColor } = YarnDetails[0];
  let updateDesignTiles = null;
  let yarnDetailArr = Array(plyNumber);

  for (let i = 0; i < plyNumber; i++) {
    //Check if all colors are same, if same no need to render
    if (YarnDetails[i] && YarnDetails[i].Color !== firstColor) {
      updateDesignTiles = true;
    }
    yarnDetailArr[i] = YarnDetails[i] ? YarnDetails[i] : YarnDetails[0];
  }

  if (plyNumber === 1) {
    //check if all the mix ply contains color 1, if yes dont render
    for (let i = 0; i < YarnDetails.length; i++) {
      if (YarnDetails && YarnDetails[i].Color !== firstColor) {
        updateDesignTiles = true;
      }
    }
  }
  d.DesignColors[colorIndex].YarnDetails = yarnDetailArr;
  if (updateDesignTiles) {
    updateDesignTiles = { colorIndex, YarnDetails };
    const hash = generateHash(d);
    return { ...state, designDetails: d, customizationFlag: true, updateDesignTiles, hash };
  }

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    setColorsInSession(d.DesignColors, state.fullpath);
  }
  return { ...state, designDetails: d, customizationFlag: true };
};
const toggleCarving = (state, payload) => {
  const { designDetails, selectedColor, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));

  if (payload && payload.length > 0) {
    payload.forEach(element => {
      d.DesignColors[element].Carving = !d.DesignColors[element].Carving;
    });
  } else if (payload && payload.length === 0) {
    let firstColorCarving = d.DesignColors[0].Carving;
    d.DesignColors.forEach((designColor, i) => {
      designColor.Carving = !firstColorCarving;
    });
  } else {
    d.DesignColors[selectedColor].Carving = !d.DesignColors[selectedColor].Carving;
  }
  let tilesToUpdate = payload ? null : { colorIndex: selectedColor };

  return {
    ...state,
    designDetails: d,
    customizationFlag: true,
    updateDesignTiles: null,
    updateNormapTiles: tilesToUpdate,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    hash: generateHash(d)
  };
};
const changeColorPile = (state, { colorIndex, pileHeight }) => {
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...state.designDetails }));
  d.DesignColors[colorIndex].PileHeight = pileHeight;
  const colorNumbers = updateColorNumbers(d.DesignColors);
  return {
    ...state,
    designDetails: d,
    customizationFlag: true,
    colorNumbers: colorNumbers,
    selectedColor: colorIndex,
    updateDesignTiles: null,
    updateNormapTiles: { colorIndex },
    hash: generateHash(d),
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }]
  };
};
const changeColorScheme = (state, payload) => {
  const { fullpath, designName } = payload;
  const details = normalizeUnit({
    ...payload.designDetails,
    defaultUnit: window.flags.defaultUnit
  });
  let designColorsPalette = payload.designDetails.DesignColors || [];
  if (window.flags.applyColorPaletteinVariations) {
    const DesignColors = window.sessionStorage.getItem("DesignColors");

    if (DesignColors) {
      designColorsPalette = JSON.parse(DesignColors);
    }
  }
  const designDetails = {
    ...payload.designDetails,
    ...details,
    OrderProperties: {
      QualityIndex: window.flags.defaultQualityIndex
    },
    DesignColors: designColorsPalette
  };
  return {
    ...state,
    history: [],
    designDetails,
    hash: generateHash(designDetails, fullpath),
    updateDesignTiles: null,
    updateNormapTiles: null,
    designName: designName,
    currentDesignPath: "",
    totalKnots: designColorsPalette.map(c => c.Knots).reduce((a, b) => a + b, 0),
    colorNumbers: updateColorNumbers(designColorsPalette),
    fullpath,
    selectedColor: -1,
    customizationFlag: true
  };
};
const changeColorPalette = (state, colorPalette) => {
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  // colorPalette.sort(function(a, b) {
  //   var x = colorPalette.indexOf(a),
  //     y = colorPalette.indexOf(b);
  //   return d.DesignColors[x].Knots > d.DesignColors[y].Knots
  //     ? -1
  //     : d.DesignColors[x].Knots < d.DesignColors[y].Knots
  //     ? 1
  //     : 0;
  // });

  colorPalette.forEach((item, index) => {
    if (d.DesignColors[index] && d.DesignColors[index].YarnDetails.length === 1) {
      d.DesignColors[index].Color = item.Value;
      d.DesignColors[index].ColorName = item.Name;
      d.DesignColors[index].YarnDetails[0].Color = item.Value;
      d.DesignColors[index].YarnDetails[0].ColorName = item.Name;
    }
  });
  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    setColorsInSession(d.DesignColors, state.fullpath);
  }
  return {
    ...state,
    customizationFlag: true,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    designDetails: d,
    updateDesignTiles: { colorIndex: -1 },
    updateNormapTiles: null,
    hash: generateHash(d)
  };
};
// const convertUnit = (from, to, valueArr) => {
//   return valueArr.map(val =>
//     Number(
//       convert(val)
//         .from(from)
//         .to(to)
//         .toFixed(2)
//     )
//   );
//  };
// const convertUnit_Arr = (from, to, valueArr, setCustomConversion = false, customInToCmFactor) => {
//   return valueArr.map(val => convertUnit(from, to, val, setCustomConversion, customInToCmFactor));
// };

const normalizeUnit = ({
  PhysicalWidth,
  PhysicalHeight,
  defaultUnit,
  Unit,
  setCustomConversion = false,
  customInToCmFactor
}) => {
  let width = PhysicalWidth,
    height = PhysicalHeight,
    defUnit = defaultUnit;

  // console.log(
  //   Unit,
  //   defUnit,
  //   PhysicalWidth,
  //   PhysicalHeight,
  //   setCustomConversion,
  //   customInToCmFactor
  // );
  if (!defaultUnit) {
    if (Unit === "in") defUnit = "ft";
    else defUnit = Unit;
  }

  [width, height] = convertUnit_Arr(
    Unit,
    defUnit,
    [PhysicalWidth, PhysicalHeight],
    setCustomConversion,
    customInToCmFactor
  );
  if (
    defUnit === "cm" ||
    window.flags.ordersheet.roundOffToFtOrCm ||
    window.flags.ordersheet.roundOffToNearestHalf
  ) {
    width = window.flags.ordersheet.roundOffToNearestHalf
      ? Math.round(width * 2) / 2
      : Math.round(width);
    height = window.flags.ordersheet.roundOffToNearestHalf
      ? Math.round(height * 2) / 2
      : Math.round(height);
  }
  if (defUnit === "cm" && window.flags.ordersheet.roundOffToNearest5) {
    width = Math.round(width / 5) * 5;
    height = Math.round(height / 5) * 5;
  }
  return { PhysicalWidth: width, PhysicalHeight: height, Unit: defUnit };
};
const updateColorNumbers = designColors => {
  const { colorNumberMode, differentSymbolForAllColors = false } = window.flags.colorAreaSwatch;
  var map = {};
  var result = [];
  var cnt = 1;
  var element, temp;
  if (differentSymbolForAllColors) {
    for (var j = 0; j < designColors.length; j++) {
      if (colorNumberMode == colorNumberModes.NUMERIC) {
        result[j] = j + 1;
      } else {
        result[j] = String.fromCharCode(65 + j);
      }
    }
  } else {
    designColors.forEach((designColor, i) => {
      element = designColor.ColorName + "-" + designColor.PileHeight;
      if (!map[element]) {
        map[element] = [cnt++];
      }
      map[element].push(i);
    });
    for (element in map) {
      var indices = map[element];
      if (colorNumberMode === colorNumberModes.ALPHA) temp = String.fromCharCode(64 + indices[0]);
      else temp = indices[0];
      if (indices.length === 2) {
        result[indices[1]] = temp;
      } else {
        for (var j = 1; j < indices.length; ++j) {
          result[indices[j]] = temp + String.fromCharCode(96 + j);
        }
      }
    }
  }
  return result;
};

function undoDesignColor(state) {
  const his = [...state.history];
  if (!his.length) return state;
  const currentState = his.pop();
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = currentState;
  const pushToFutureState = {
    designDetails: state.designDetails,
    updateDesignTiles: state.updateDesignTiles,
    updateNormapTiles: state.updateNormapTiles,
    hash: state.hash
  };
  return {
    ...state,
    updateNormapTiles,
    designDetails,
    updateDesignTiles,
    hash,
    history: his,
    future: [...state.future, pushToFutureState]
  };
}

function redoDesignColor(state) {
  const his = [...state.future];
  if (!his.length) return state;
  const currentState = his.pop();
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = currentState; // Shift instead of pop
  const pushToPastState = {
    designDetails: state.designDetails,
    updateDesignTiles: state.updateDesignTiles,
    updateNormapTiles: state.updateNormapTiles,
    hash: state.hash
  };
  return {
    ...state,
    updateNormapTiles,
    designDetails,
    updateDesignTiles,
    hash,
    future: his,
    history: [...state.history, pushToPastState]
  };
}

const setTileDetails = (state, payload) => {
  // const { props1x, props2x, props4x } = payload;
  return {
    ...state,
    tileDetails: payload
  };
};
const generateHash = (details, fullpath = "") => {
  const des = JSON.stringify(details);
  // const key = sessionStorage.getItem("apikey")
  return MD5(des + fullpath);
};
const getCarvingColorNumbers = designColors => {
  let carvingColorNumbers = [];
  if (designColors && designColors.length > 0) {
    if (checkPartialCarving(designColors)) {
      designColors.forEach((designColor, i) => {
        if (designColor.Carving) {
          carvingColorNumbers.push(i);
        }
      });
    } else {
    }
  }
  return carvingColorNumbers;
};
const checkPartialCarving = designColors => {
  let partialCarving = false;
  let firstColorCarving = designColors[0].Carving;
  designColors.forEach((designColor, i) => {
    if (firstColorCarving !== designColor.Carving) {
      partialCarving = true;
    }
  });
  return partialCarving;
};
function DesignDetailStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(designDetailReducer, designDetailState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useDesignDetailState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useDispatchDesignDetail() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export {
  DesignDetailStateProvider,
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
};
